import * as React from "react"
import Layout from "../components/layout";
import usePosts from "../hooks/use-posts";
import PostPreview from "../components/postpreview";
import PrimeSection from "../components/primesection";
import styled from "@emotion/styled";
import facepaint from 'facepaint'
const mq = facepaint([
    '@media(min-width: 767px)',
])


const PostWrapper = styled('section')`
  ${mq({
    display:'flex',
    flexDirection: ['column', 'row'],
    justifyContent: 'flex-start',
    flexWrap:'wrap',
    article:{
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-between',
      alignItems:'flex-start',
      width:['default', '50%'],
      marginTop: ['0', '1.5rem'],
      minHeight:'200px',
      padding: '2%',
      
      '& *':{
          marginTop: '0.8rem'
      }
    }
})};
`
const BlogPage = () => {

    const posts = usePosts();

    return (
        <Layout>
            <PrimeSection>
                <h1>My Blog</h1>
                <p>Behind the scenes of products I’ve built, projects I’ve worked on, designs I have created and more.</p>
            </PrimeSection>

            <section>
                <h2>Recent Posts</h2>
                <PostWrapper>
                    {posts.map(post => (
                        <PostPreview key={post.slug} post={post} />
                    ))}
                </PostWrapper>
            </section>
        </Layout>
    )
}

export default BlogPage;
