import { graphql, useStaticQuery } from 'gatsby';
const usePosts = () => {
    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark {
                edges {
                    node {
                        frontmatter {
                            blogcoverimage
                            date
                            description
                            title
                        }
                        excerpt
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `);
    return data.allMarkdownRemark.edges.map(post => ({
        title: post.node.frontmatter.title,
        blogCoverImage: post.node.frontmatter.blogCoverImage,
        description: post.node.frontmatter.description,
        slug: post.node.fields.slug,
        excerpt: post.node.excerpt,
    }));
};
export default usePosts;
