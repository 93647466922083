import React from 'react';
import { Link } from 'gatsby';
import ReadLink from "../readlink";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

const PPLink = styled.link`
  
`

const PostPreview = ({ post }) => (
    <article
    >
        <h3
            css={css`
                color: whitesmoke;
                text-transform: capitalize;
                text-decoration: none;
            `}
        >
            {post.title}
        </h3>
        <p>{post.excerpt}</p>
        <ReadLink
            css={css`
color: #76E5FF;            
`}
            to={post.slug}>read this post &rarr;</ReadLink>
    </article>
);

export default PostPreview;
